import React from 'react';
import 'bulma/css/bulma.css';
import './App.css';
import Layout from './layout/Layout';


function App() {
  return (
    <Layout />
  );
}

export default App;
